import React, { createContext, useReducer } from "react";

const initialState = {
    currChapter: {},
    currVerse: {},
    verses: [],
    posts: [],
    liked: [],
    disliked: [],
    numLikes: {},
    numDislikes: {},
};

const reducer = (state, action) => {
    switch (action.type) {
        case "FETCH_INIT":
            return initialState;

        case "SHIFT_VERSE":
            return {
                ...state,
                currVerse: action.next,
                posts: [],
            };

        case "SET_FIELD":
            return { ...state, [action.field]: action.value };

        default:
            return state;
    }
};

const PostContext = createContext({
    state: {},
    dispatch: () => {},
});

export const PostProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <PostContext.Provider value={{ state, dispatch }}>
            {children}
        </PostContext.Provider>
    );
};

export default PostContext;
