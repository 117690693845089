import React, { useState, useContext, useEffect } from "react";

import ModComment from "../ModComment";
import UserContext from "../../../contexts/UserContext";
import InfiniteScroll from "react-infinite-scroll-component";
import { LinearProgress } from "@mui/material";
import { BASE_URL } from "../../../theme";

const ModPendingFeed = () => {
    const { modChapters } = useContext(UserContext);
    const [comments, setComments] = useState([]);
    const [next, setNext] = useState(null);

    const loadMore = async () => {
        try {
            const response = await fetch(next, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("access")}`,
                },
            });

            const data = await response.json();

            setComments((lst) => [...lst, ...data.results]);

            data.next ? setNext(data.next) : setNext(null);
        } catch (error) {
            console.error("Error fetching more mod comments:", error);
        }
    };
    useEffect(() => {
        const getPending = async () => {
            try {
                const response = await fetch(
                    `${BASE_URL}/comments/mod/pending/?` +
                        new URLSearchParams(
                            modChapters.map((c) => ["chapters", c])
                        ).toString(),
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem(
                                "access"
                            )}`,
                        },
                    }
                );
                const data = await response.json();
                setComments(data.results);
                data.next ? setNext(data.next) : setNext(null);
            } catch (error) {
                console.error("Error fetching pending mod comments:", error);
            }
        };

        getPending();
    }, [modChapters]);

    return (
        <div
            className="mod-pending-feed"
            sx={{
                margin: "2rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}>
            <InfiniteScroll
                dataLength={comments.length}
                next={loadMore}
                hasMore={next}
                loader={<LinearProgress />}>
                {comments.map((comment, i) => (
                    <ModComment
                        key={"mod-comment-" + i}
                        comment={comment}
                        comments={comments}
                        setComments={setComments}
                    />
                ))}
            </InfiniteScroll>
        </div>
    );
};

export default ModPendingFeed;
