import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createRanges } from "../VersePost";
import {
    Card,
    CardActionArea,
    CardContent,
    CardHeader,
    Typography,
} from "@mui/material";
import { BASE_URL, CARD_WIDTH } from "../../../theme";
import VerseChip from "../../generic/VerseChip";
import OptionsDropdown from "../../generic/OptionsDropdown";
import EditPostForm from "../EditPostForm";
import LangContext from "../../../contexts/LangContext";

const UserPost = ({ post, posts, setPosts }) => {
    const [ranges, setRanges] = useState([]);
    const [displayEdit, setDisplayEdit] = useState(false);

    const {langTokens} = useContext(LangContext);

    let navigate = useNavigate();

    useEffect(() => {
        createRanges(post, setRanges);
    }, [post]);

    const delPost = () => {
        fetch(`${BASE_URL}/posts/del/${post.id}/`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
        })
            .then(async (res) => {
                if (res.status !== 204) {
                    return Promise.reject(res);
                }
                setPosts(posts.filter((p) => p.id !== post.id));
            })
            .catch((err) => console.log(err));
    };

    return (
        <>
            <Card
                sx={{
                    width: CARD_WIDTH,
                    margin: "1rem",
                }}>
                <CardHeader
                    title={
                        <Typography align="left" fontSize={14}>
                            {`${post.user.username} • ${new Date(
                                post.date_created
                            )
                                .toDateString()
                                .slice(4)}`}
                        </Typography>
                    }
                    action={
                        <OptionsDropdown
                            style={{ marginLeft: "auto" }}
                            icon={"VERT"}
                            options={[
                                {
                                    label: langTokens.edit,
                                    onClick: () => {
                                        setDisplayEdit(true);
                                    },
                                },
                                {
                                    label: langTokens.delete,
                                    onClick: delPost,
                                },
                            ]}
                        />
                    }
                />

                {displayEdit ? (
                    <EditPostForm
                        post={post}
                        setDisplayForm={setDisplayEdit}
                        updatePosts={true}
                        setPosts={setPosts}
                    />
                ) : (
                    <CardActionArea
                        onClick={() => navigate(`/post/${post.id}/`)}>
                        <CardContent
                            align="left"
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                            }}>
                            <Typography
                                fontSize={14}
                                sx={{ whiteSpace: "pre-wrap" }}>
                                {post.text !== undefined && post.text}
                            </Typography>

                            <div
                                style={{
                                    display: "inline-flex",
                                    paddingBottom: "0",
                                    paddingTop: "0.3rem",
                                }}>
                                {ranges.map((range, i) => (
                                    <VerseChip key={"chip" + i} range={range} />
                                ))}
                            </div>
                        </CardContent>
                    </CardActionArea>
                )}
            </Card>
        </>
    );
};

export default UserPost;
