import React, { createContext, useState, useEffect, useContext } from "react";
import AuthContext from "./AuthContext";
import { BASE_URL } from "../theme";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const { tokens } = useContext(AuthContext);

    const [username, setUsername] = useState("");
    const [userId, setUserId] = useState(0);
    const [isSuperUser, setIsSuperUser] = useState(false);
    const [modChapters, setModChapters] = useState([]);
    const [modTags, setModTags] = useState([]);

    useEffect(() => {
        if (tokens) {
            const fetchModTags = async () => {
                await fetch(`${BASE_URL}/reviews/tags/all/`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "access"
                        )}`,
                    },
                })
                    .then((res) => res.json())
                    .then((data) => {
                        setModTags(data.results);
                    })
                    .catch((err) => console.log(err));
            };

            const fetchModData = async () => {
                await fetch(`${BASE_URL}/accounts/mod/profile/`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "access"
                        )}`,
                    },
                })
                    .then((res) => res.json())
                    .then((data) => {
                        setModChapters(data.chapters);
                        fetchModTags();
                    })
                    .catch((err) => console.log(err));
            };

            const fetchData = async () => {
                await fetch(`${BASE_URL}/accounts/profile/`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "access"
                        )}`,
                    },
                })
                    .then((res) => res.json())
                    .then((data) => {
                        setUsername(data.username);
                        setUserId(data.id);
                        setIsSuperUser(data.is_superuser);

                        if (data.is_superuser) {
                            fetchModData();
                        }
                    })
                    .catch((err) => console.log(err));
            };

            fetchData();
        }
    }, [tokens]);

    return (
        <UserContext.Provider
            value={{
                username,
                setUsername,
                userId,
                setUserId,
                isSuperUser,
                setIsSuperUser,
                modChapters,
                setModChapters,
                modTags,
                setModTags,
            }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserContext;
