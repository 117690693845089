import React, { useContext, useEffect, useRef, useState } from "react";
import {
    Card,
    CardContent,
    IconButton,
    LinearProgress,
    Typography,
} from "@mui/material";
import { useNavigate, useParams, Link } from "react-router-dom";
import "./singleVerse.css";

import UserContext from "../../contexts/UserContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import VersesContext from "../../contexts/VersesContext";
import LoadingScreen from "../../components/generic/LoadingScreen";
import VersePosts from "../../components/posts/VersePosts";
import PostForm from "../../components/posts/PostForm";
import ChaptersContext from "../../contexts/ChaptersContext";
import LoadingContext from "../../contexts/LoadingContext";
import PostContext, { PostProvider } from "../../contexts/PostContext";
import { BASE_URL, CARD_WIDTH } from "../../theme";
import InfiniteScroll from "react-infinite-scroll-component";
import LangContext from "../../contexts/LangContext";

const SingleVerse = () => {
    const { verses, chapter, setChapter, fetchVerses } =
        useContext(VersesContext);
    const { username, userId, isSuperUser } = useContext(UserContext);
    const { chapters, fetchChapters } = useContext(ChaptersContext);

    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const { state, dispatch } = useContext(PostContext);
    const { langTokens } = useContext(LangContext);

    const { num, chapNum } = useParams();

    const [fetchLoading, setFetchLoading] = useState(false);
    const [next, setNext] = useState(null);

    const last = useRef(0);

    let navigate = useNavigate();

    const loadMorePosts = async () => {
        try {
            const response = await fetch(next);

            const data = await response.json();

            dispatch({
                type: "SET_FIELD",
                field: "posts",
                value: [...state.posts, ...data.results],
            });

            data.next ? setNext(data.next) : setNext(null);
        } catch (error) {
            console.error("Error fetching more verse posts:", error);
        }
    };
    useEffect(() => {
        const fetchVersePosts = async (verse) => {
            try {
                const response = await fetch(
                    `${BASE_URL}/posts/verse/${verse.id}/`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
                const data = await response.json();

                data.next ? setNext(data.next) : setNext(null);

                return data.results;
            } catch (error) {
                console.error("Error fetching verse posts:", error);
            }
        };

        const fetchData = async () => {
            try {
                setFetchLoading(true);

                if (chapters.length === 0) {
                    fetchChapters();
                }

                let currChapter = chapters.find(
                    (chapter) => chapter.num === parseInt(chapNum)
                );
                if (!currChapter) {
                    throw new Error("Chapter not found");
                }

                dispatch({
                    type: "SET_FIELD",
                    field: "currChapter",
                    value: currChapter,
                });

                let versesRes = verses;
                if (verses.length === 0 || currChapter.num !== chapter.num) {
                    versesRes = await fetchVerses(currChapter);
                }

                setChapter(currChapter);
                const currVerse = versesRes.find(
                    (verse) => verse.num === parseInt(num)
                );

                if (!currVerse) {
                    throw new Error("Verse not found");
                }

                dispatch({
                    type: "SET_FIELD",
                    field: "currVerse",
                    value: currVerse,
                });

                last.current = versesRes[versesRes.length - 1].num;

                const posts = await fetchVersePosts(currVerse);
                dispatch({
                    type: "SET_FIELD",
                    field: "posts",
                    value: posts,
                });
                setFetchLoading(false);
            } catch (err) {
                console.error("Error fetching data: ", err);
            }
        };

        dispatch({ type: "FETCH_INIT" });
        fetchData();
    }, [
        chapNum,
        num,
        chapter,
        chapters,
        verses,
        fetchChapters,
        fetchVerses,
        setChapter,
        dispatch,
    ]);

    const goBack = () => {
        let next = parseInt(num) - 1;
        dispatch({ type: "SHIFT_VERSE", next: state.verses[next - 1] });
        setIsLoading(true);
        navigate("/quran/" + state.currChapter.num + "/verse/" + next);
    };

    const goForward = () => {
        let next = parseInt(num) + 1;
        dispatch({ type: "SHIFT_VERSE", next: state.verses[next - 1] });
        setIsLoading(true);
        navigate("/quran/" + state.currChapter.num + "/verse/" + next);
    };

    return (
        <div className="verse-wrapper">
            {isLoading || fetchLoading ? (
                <LoadingScreen />
            ) : (
                <div className="verse-page">
                    <InfiniteScroll
                        dataLength={state.posts.length}
                        next={loadMorePosts}
                        hasMore={next}
                        loader={<LinearProgress />}>
                        <Card
                            variant="outlined"
                            sx={{
                                width: CARD_WIDTH,
                                border: "none",
                                boxShadow: "none",
                            }}>
                            {num !== undefined && parseInt(num) === 1 && (
                                <IconButton disabled>
                                    <ArrowBackIcon />
                                </IconButton>
                            )}

                            {num !== undefined && parseInt(num) !== 1 && (
                                <IconButton onClick={goBack}>
                                    <ArrowBackIcon color="primary" />
                                </IconButton>
                            )}

                            {state.currChapter !== undefined &&
                                state.currVerse !== undefined &&
                                state.currChapter.num +
                                    ":" +
                                    state.currVerse.num}

                            {num !== undefined &&
                                parseInt(num) !== last.current && (
                                    <IconButton onClick={goForward}>
                                        <ArrowForwardIcon color="primary" />
                                    </IconButton>
                                )}

                            {num !== undefined &&
                                parseInt(num) === last.current && (
                                    <IconButton disabled>
                                        <ArrowForwardIcon />
                                    </IconButton>
                                )}
                        </Card>
                        <Card
                            variant="outlined"
                            className="verse-card"
                            sx={{
                                width: CARD_WIDTH,
                                border: "none",
                            }}>
                            <CardContent>
                                <Typography
                                    fontSize={22}
                                    style={{
                                        fontFamily: ["'Amiri'", "serif"].join(
                                            ","
                                        ),
                                    }}>
                                    {state.currVerse !== undefined &&
                                        state.currVerse.text_ara}
                                </Typography>
                                <Typography style={{ paddingTop: "20px" }}>
                                    {state.currVerse !== undefined &&
                                        state.currVerse.text_eng}
                                </Typography>
                            </CardContent>
                        </Card>

                        {username === "" && (
                            <Link
                                to="/login"
                                style={{
                                    textDecoration: "none",
                                    color: "#ff9d3f",
                                    paddingTop: 10,
                                }}>
                                <Typography>
                                    {langTokens.login_prompt}
                                </Typography>
                            </Link>
                        )}
                        {username !== "" && isSuperUser && <PostForm />}

                        {state.posts !== undefined &&
                            state.posts.length > 0 && (
                                <VersePosts
                                    versePosts={state.posts}
                                    userId={userId}
                                />
                            )}
                    </InfiniteScroll>
                </div>
            )}
        </div>
    );
};

const VersePage = () => {
    return (
        <PostProvider>
            <SingleVerse />
        </PostProvider>
    );
};
export default VersePage;
