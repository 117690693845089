import React, { createContext, useState, useEffect } from "react";
import { BASE_URL } from "../theme";

const AuthContext = createContext({
    tokens: null,
    setTokens: () => {},
});

export const AuthProvider = ({ children }) => {
    const [tokens, setTokens] = useState(() =>
        localStorage.getItem("authTokens")
            ? JSON.parse(localStorage.getItem("authTokens"))
            : null
    );

    const loginUser = async (username, password) => {
        const res = await fetch(`${BASE_URL}/accounts/login/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                username: username,
                password: password,
            }),
        });
        const data = await res.json();
        if (res.ok) {
            setTokens(data);
            localStorage.setItem("access", data.access);
            localStorage.setItem("refresh", data.refresh);
            return true;
        } else {
            return false;
        }
    };

    const logOut = () => {
        setTokens(null);
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
    };

    useEffect(() => {
        const refreshToken = async () => {
            const res = await fetch(
                `${BASE_URL}/accounts/token/refresh/`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        refresh: tokens.refresh,
                    }),
                }
            );
            const data = await res.json();
            // TODO: error-proof w login component
            if (res.ok) {
                console.log("refresh token success");
                setTokens(data);

                localStorage.setItem("access", data.access);
                localStorage.setItem("refresh", data.refresh);
            } else {
                console.log("refresh token failed");
                logOut();
            }
        };

        if (tokens) {
            const interval = setInterval(() => {
                refreshToken();
            }, 4 * 60 * 1000); // Refresh every 4 minutes
            return () => clearInterval(interval);
        }
    }, [tokens]);

    return (
        <AuthContext.Provider value={{ tokens, loginUser, logOut }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
