import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Card, CardActionArea, Typography } from "@mui/material";
import ChaptersContext from "../../../contexts/ChaptersContext";

const ChapterList = () => {
    let navigate = useNavigate();
    const { chapters } = useContext(ChaptersContext);
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
            }}>
            {chapters.map((chapter, i) => (
                <Card
                    key={"chapter-" + i}
                    style={{
                        width: "15rem",
                        height: "5rem",
                        margin: "1rem",
                        borderRadius: "0.5rem",
                        display: "table-cell",
                        verticalAlign: "middle",
                    }}>
                    <CardActionArea
                        onClick={() => {
                            navigate("/quran/" + chapter.num + "/");
                        }}>
                        <div
                            style={{
                                padding: "0.5rem",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}>
                            <Typography
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                                fontSize={40}>
                                {chapter.num}
                            </Typography>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}>
                                <Typography
                                    align="left"
                                    style={{
                                        fontSize: 20,
                                    }}>
                                    {chapter.name_eng}
                                </Typography>
                                <Typography
                                    align="right"
                                    style={{
                                        fontSize: 22,
                                        fontFamily: ["'Amiri'", "serif"].join(
                                            ","
                                        ),
                                    }}>
                                    {chapter.name_ara}
                                </Typography>
                            </div>
                        </div>
                    </CardActionArea>
                </Card>
            ))}
        </div>
    );
};

export default ChapterList;
