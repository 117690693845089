import {
    Card,
    Avatar,
    Typography,
    CardContent,
    CardActions,
    Button,
    Box,
} from "@mui/material";
import UserContext from "../../../contexts/UserContext";
import "../../comments/Comment/comment.css";
import React, { useContext, useState } from "react";
import OptionsDropdown from "../../generic/OptionsDropdown";
import CommentThreadForm from "../../comments/CommentThreadForm";
import { BASE_URL, CARD_WIDTH } from "../../../theme";
import ReviewThread from "../ReviewThread";
import LangContext from "../../../contexts/LangContext";
import { useNavigate } from "react-router-dom";

const ReviewComment = ({
    comment,
    currComments,
    setCurrComments,
    margin = 0,
}) => {
    const [displayForm, setDisplayForm] = useState(false);
    const [displayThread, setDisplayThread] = useState(false);
    const { userId } = useContext(UserContext);
    const { langTokens } = useContext(LangContext);

    let navigate = useNavigate();

    const delComment = () => {
        fetch(`${BASE_URL}/reviews/comments/del/${comment.id}/`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
        })
            .then(async (res) => {
                if (res.status !== 204) {
                    return Promise.reject(res);
                }
                setCurrComments(
                    currComments.filter((c) => c.id !== comment.id)
                );
            })
            .catch((err) => console.log(err));
    };

    const submitReviewComm = (text) => {
        fetch(`${BASE_URL}/reviews/comments/create/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
            body: JSON.stringify({
                text: text,
                user: userId,
                review: comment.review,
                parent: comment.id,
            }),
        }).then((res) => {
            if (res.status !== 201) {
                return Promise.reject(res);
            }
            setDisplayForm(false);
        });
    };
    return (
        <div
            style={{
                paddingBottom: "0.8rem",
                width: "100%",
            }}>
            <Card
                key={"comment-" + comment.id}
                style={{
                    border: "none",
                    boxShadow: "none",
                    width: "100%",
                }}>
                <CardContent
                    style={{
                        display: "flex",
                        flexDirection: "row",
                    }}>
                    <Avatar className="avatar">
                        {comment.user.username[0].toUpperCase()}
                    </Avatar>
                    <Box
                        sx={{
                            paddingLeft: "0.5rem",
                        }}>
                        <Typography fontWeight={"500"} fontSize={13}>
                            {comment.user.username +
                                " • " +
                                new Date(comment.date_created)
                                    .toDateString()
                                    .slice(4)}
                        </Typography>

                        <Typography
                            sx={{ whiteSpace: "pre-wrap" }}
                            fontSize={13}>
                            {comment.text}
                        </Typography>
                    </Box>
                    <div style={{ marginLeft: "auto" }}>
                        {comment.user.id === userId && (
                            <OptionsDropdown
                                icon={"HORIZ"}
                                options={[
                                    {
                                        label: langTokens.delete,
                                        onClick: delComment,
                                    },
                                ]}
                            />
                        )}
                    </div>
                </CardContent>
                <CardActions disableSpacing>
                    <Button
                        color="inherit"
                        onClick={() =>
                            userId === 0
                                ? navigate("/login")
                                : setDisplayForm(true)
                        }>
                        <Typography fontSize={13} fontWeight={"bold"}>
                            {langTokens.reply}
                        </Typography>
                    </Button>

                    {comment.responses.length > 0 && !displayThread && (
                        <Button
                            color="inherit"
                            onClick={() => setDisplayThread(true)}>
                            <Typography fontSize={13} fontWeight={"bold"}>
                                {langTokens.view_replies}
                            </Typography>
                        </Button>
                    )}

                    {comment.responses.length > 0 && displayThread && (
                        <Button
                            color="inherit"
                            onClick={() => setDisplayThread(false)}>
                            <Typography fontSize={13} fontWeight={"bold"}>
                                {langTokens.hide_replies}
                            </Typography>
                        </Button>
                    )}
                </CardActions>

                {displayForm && (
                    <Box sx={{ margin: "1rem" }}>
                        <CommentThreadForm
                            nested={true}
                            mod={true}
                            displayFunc={setDisplayForm}
                            submitFunc={submitReviewComm}
                            postId={comment.review}
                            parent={comment.id}
                        />
                    </Box>
                )}

                {displayThread && (
                    <ReviewThread
                        key={"r-thread-" + comment.id}
                        comments={comment.responses}
                        margin={margin}
                    />
                )}
            </Card>
        </div>
    );
};

export default ReviewComment;
