import React, { useContext, useEffect, useState } from "react";
import {
    Typography,
    Card,
    CardHeader,
    Avatar,
    CardContent,
    Box,
    TextField,
    Button,
    CardActions,
} from "@mui/material";
import "./profile.css";
import { BASE_URL, CARD_WIDTH } from "../../../theme";
import LangContext from "../../../contexts/LangContext";

const ModProfile = () => {
    const [profile, setProfile] = useState({});
    const [description, setDescription] = useState("");
    const [subtitle, setSubtitle] = useState("");

    const { langTokens } = useContext(LangContext);

    useEffect(() => {
        fetch(`${BASE_URL}/accounts/mod/profile/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
        })
            .then((res) => res.json())
            .then((data) => {
                setProfile(data);
            })
            .catch((err) => console.log(err));
    }, []);

    const addBio = async () => {
        if (subtitle !== "" || description !== "") {
            let body = { user: profile.id };
            if (subtitle !== "") {
                body["subtitle"] = subtitle;
            }

            if (description !== "") {
                body["description"] = description;
            }
            await fetch(`${BASE_URL}/accounts/bio/add/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("access")}`,
                },
                body: JSON.stringify(body),
            })
                .then(async (res) => {
                    if (res.status !== 201) {
                        return Promise.reject(res);
                    }
                })
                .catch((err) => console.log(err));
        }
    };

    const editBio = async () => {
        await fetch(
            `${BASE_URL}/accounts/bio/edit/${profile.bio.id}/`,
            {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("access")}`,
                },
                body: JSON.stringify({
                    user: profile.id,
                    subtitle: subtitle,
                    description: description,
                }),
            }
        )
            .then(async (res) => {
                if (!res.ok) {
                    return Promise.reject(res);
                }
            })
            .catch((err) => console.log(err));
    };

    const saveBio = () => {
        if (profile.bio) {
            editBio();
        } else {
            // bio does not exist, create one
            addBio();
        }
    };
    return (
        <div className="mod-profile">
            <Card sx={{ width: CARD_WIDTH }}>
                <CardHeader
                    title={
                        <div style={{ display: "flex", gap: "0.5rem" }}>
                            <Avatar />
                            <Typography
                                align="left"
                                variant="h5"
                                sx={{ paddingTop: "0.3rem" }}>
                                {langTokens.your_profile}
                            </Typography>
                        </div>
                    }
                />
                <CardContent>
                    <Box sx={{ paddingBottom: "1rem" }}>
                        <Typography align="left" variant="h6">
                            {langTokens.your_sub}
                        </Typography>
                        <TextField
                            size="small"
                            variant="outlined"
                            multiline
                            align="left"
                            fullWidth
                            defaultValue={
                                profile.bio ? profile.bio.subtitle : ""
                            }
                            placeholder={langTokens.sub_placeholder}
                            inputProps={{ style: { fontSize: 13 } }}
                            onChange={(e) => {
                                setSubtitle(e.target.value);
                            }}
                        />
                    </Box>
                    <Box sx={{ paddingBottom: "1rem" }}>
                        <Typography align="left" variant="h6">
                            {langTokens.your_desc}
                        </Typography>
                        <TextField
                            size="small"
                            variant="outlined"
                            multiline
                            align="left"
                            fullWidth
                            defaultValue={
                                profile.bio ? profile.bio.description : ""
                            }
                            placeholder={langTokens.desc_placeholder}
                            inputProps={{ style: { fontSize: 13 } }}
                            onChange={(e) => {
                                setDescription(e.target.value);
                            }}
                        />
                    </Box>
                </CardContent>
                <CardActions disableSpacing>
                    <Button sx={{ marginLeft: "auto" }} onClick={saveBio}>
                        {langTokens.save}
                    </Button>
                </CardActions>
            </Card>
        </div>
    );
};

export default ModProfile;
