import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./verses.css";
import VerseCard from "../../components/quran/VerseCard";
import VersesContext from "../../contexts/VersesContext";
import LoadingScreen from "../../components/generic/LoadingScreen";
import ChaptersContext from "../../contexts/ChaptersContext";
import { LinearProgress, Typography } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";

const Verses = () => {
    const { verses, chapter, setChapter, fetchVerses, next, loadMoreVerses } =
        useContext(VersesContext);
    const { chapters, fetchChapters } = useContext(ChaptersContext);

    const { num } = useParams();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                if (chapters.length === 0) {
                    await fetchChapters();
                }

                let currChapter = chapters.find(
                    (chapter) => chapter.num === parseInt(num)
                );
                if (!currChapter) {
                    throw new Error("Chapter not found");
                }

                if (verses.length === 0 || parseInt(num) !== chapter.num) {
                    await fetchVerses(currChapter);
                }

                setChapter(currChapter);

                setIsLoading(false);
            } catch (err) {
                console.error("Error fetching data:", err);
                setIsLoading(false);
            }
        };
        fetchData();
    }, [
        num,
        chapters,
        chapter.num,
        verses,
        fetchChapters,
        fetchVerses,
        setChapter,
    ]);

    return (
        <div className="verses-wrapper">
            {isLoading ? (
                <LoadingScreen />
            ) : (
                <div className="verses">
                    <InfiniteScroll
                        dataLength={verses.length}
                        next={loadMoreVerses}
                        hasMore={next}
                        loader={<LinearProgress />}>
                        <Typography variant="h4">{chapter.name_eng}</Typography>
                        <Typography
                            variant="h4"
                            style={{
                                fontFamily: ["'Amiri'", "serif"].join(","),
                            }}>
                            {"(" + chapter.name_ara + ")"}
                        </Typography>
                        {verses !== undefined &&
                            verses.map((verse, i) => (
                                <div
                                    className="verse-display"
                                    key={"verse-display-" + i}>
                                    <VerseCard
                                        verse={verse}
                                        chapter={chapter}
                                    />
                                </div>
                            ))}
                    </InfiniteScroll>
                </div>
            )}
        </div>
    );
};

export default Verses;
