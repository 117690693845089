import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import ModPendingFeed from "../../../components/mod/ModPendingFeed";
import ModHistoryFeed from "../../../components/mod/ModReviewedFeed";
import TabPanel from "../../../components/generic/TabPanel";
import LangContext from "../../../contexts/LangContext";

const ModFeed = () => {
    const [tabVal, setTabVal] = useState(0);
    const { langTokens } = useContext(LangContext);

    return (
        <div
            style={{
                padding: "2rem",
                overflow: "auto",
                height: "90%",
                width: "100%",
            }}>
            <Typography
                variant="h4"
                align="left"
                style={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
                {langTokens.your_feed}
            </Typography>
            <Box>
                <Tabs
                    value={tabVal}
                    onChange={(_, newVal) => {
                        setTabVal(newVal);
                    }}>
                    <Tab label={langTokens.task_board} value={0} />
                    <Tab label={langTokens.my_reviews} value={1} />
                    <Tab label={langTokens.other_reviews} value={2} />
                </Tabs>
                <TabPanel value={tabVal} index={0}>
                    <ModPendingFeed />
                </TabPanel>
                <TabPanel value={tabVal} index={1}>
                    <ModHistoryFeed />
                </TabPanel>
                <TabPanel value={tabVal} index={2}>
                    <ModHistoryFeed self={false} />
                </TabPanel>
            </Box>
        </div>
    );
};

export default ModFeed;
