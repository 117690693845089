import React, { createContext, useState, useEffect } from "react";

const LoadingContext = createContext({
    loading: [],
    setIsLoading: () => {},
});

export const LoadingProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 5000);
    }, [isLoading]);

    return (
        <LoadingContext.Provider
            value={{
                isLoading,
                setIsLoading,
            }}>
            {children}
        </LoadingContext.Provider>
    );
};

export default LoadingContext;
