import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

import "../../../pages/singleVerse/singleVerse.css";
import ReviewComment from "../ReviewComment";

const ReviewThread = ({ comments, margin = 5, setup = false }) => {
    const MARGIN = 10 + margin;

    const [initComments] = useState(comments);
    const [setupComm] = useState(setup);

    const [currComments, setCurrComments] = useState([]);

    useEffect(() => {
        function buildRevTree(parent) {
            for (let i = 0; i < initComments.length; i++) {
                if (initComments[i].parent === parent.id) {
                    parent.responses.push({ ...initComments[i] });
                }
            }
            for (let i = 0; i < parent.responses.length; i++) {
                buildRevTree(parent.responses[i]);
            }
        }

        const setupThreads = () => {
            for (let i = 0; i < initComments.length; i++) {
                initComments[i].responses = [];
            }
            const threadedList = initComments.filter((c) => !c.parent);

            for (let i = 0; i < threadedList.length; i++) {
                buildRevTree(threadedList[i]);
            }

            setCurrComments(threadedList);
        };

        if (setupComm) {
            setupThreads();
        } else {
            setCurrComments(initComments);
        }
    }, [setupComm, initComments]);

    const styleOuter = {
        border: "none",
        boxShadow: "none",
        marginLeft: MARGIN,
    };

    const styleInner = {
        boxShadow: "none",
        marginLeft: MARGIN,
    };

    return (
        <>
            {currComments !== undefined && currComments.length > 0 && (
                <Box style={setup ? styleOuter : styleInner}>
                    {currComments.map((comment, i) => (
                        <div key={"review-comment-" + i}>
                            <ReviewComment
                                comment={comment}
                                margin={MARGIN}
                                currComments={currComments}
                                setCurrComments={setCurrComments}
                            />
                        </div>
                    ))}
                </Box>
            )}
        </>
    );
};

export default ReviewThread;
