import {
    AppBar,
    Box,
    Typography,
    Toolbar,
    Container,
    Button,
    IconButton,
    Drawer,
    ListItem,
    Divider,
    List,
    ListItemButton,
    ListItemText,
    Tooltip,
    Avatar,
    Menu,
    MenuItem,
} from "@mui/material";

import "./nav-bar.css";
import React, { useState, useEffect, useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../../theme";
import UserContext from "../../../contexts/UserContext";
import AuthContext from "../../../contexts/AuthContext";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import SearchBar from "../SearchBar";
import cmply_logo_white from "../../../media/cmply_logo_white.png";
import cmply_logo from "../../../media/cmply_logo.png";
import LanguageIcon from "@mui/icons-material/Language";
import LangContext from "../../../contexts/LangContext";

const NavBar = () => {
    let navigate = useNavigate();
    let TEXT_COLOR = "#ffa14b";

    const { tokens, logOut } = useContext(AuthContext);
    const { lang, langTokens, setLang } = useContext(LangContext);
    const {
        isSuperUser,
        username,
        setUsername,
        setUserId,
        setIsSuperUser,
        setModChapters,
    } = useContext(UserContext);

    const [loggedIn, setLoggedIn] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        logOut();

        setLoggedIn(false);
        setUsername("");
        setUserId(0);

        if (isSuperUser) {
            setModChapters([]);
            setIsSuperUser(false);
        }

        navigate("/");
    };

    useEffect(() => {
        if (tokens) {
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
        }
    }, [tokens]);

    return (
        <ThemeProvider theme={theme}>
            <Box
                style={{
                    alignItems: "center",
                }}>
                <AppBar
                    position="sticky"
                    style={{
                        boxShadow: "none",
                        overflow: "hidden",
                        backgroundColor: "white",
                        borderBottomStyle: "solid",
                        borderBottomWidth: "1px",
                        borderBottomColor: "#d3d3d3",
                        transform: lang === "en" ? "" : "rotateY(180deg)",
                    }}>
                    <Toolbar style={{ padding: 0 }}>
                        <Container
                            sx={{
                                display: "inline-flex",
                                justifyContent: "flex-start",
                            }}>
                            <Button
                                disableRipple
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                                className="nav-button"
                                onClick={() => {
                                    navigate("/");
                                }}>
                                <img
                                    src={cmply_logo}
                                    alt="compassionately logo"
                                    width={"26"}
                                />
                                <Typography
                                    fontSize={23}
                                    fontWeight={"bold"}
                                    component="div"
                                    sx={{
                                        color: TEXT_COLOR,
                                        paddingRight: "0.7rem",
                                        paddingLeft: "0.7rem",
                                        transform:
                                            lang === "en"
                                                ? ""
                                                : "rotateY(-180deg)",
                                        fontFamily: [
                                            lang === "en"
                                                ? "'TT Norms'"
                                                : "'Tajawal'",
                                            "sans-serif",
                                        ].join(","),
                                    }}>
                                    {lang === "en"
                                        ? "compassionately"
                                        : "كُمبَشِنَتلي"}
                                </Typography>
                            </Button>
                        </Container>

                        <Container
                            sx={{
                                display: "inline-flex",
                                justifyContent: "flex-end",
                            }}>
                            <SearchBar style={{ paddingRight: "5rem" }} />

                            {!loggedIn && (
                                <Tooltip title={langTokens.login}>
                                    <IconButton
                                        onClick={() => {
                                            navigate("/login");
                                        }}>
                                        <PersonIcon
                                            style={{ color: TEXT_COLOR }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            )}

                            <Tooltip title={langTokens.language}>
                                <IconButton
                                    onClick={(e) =>
                                        setAnchorEl(e.currentTarget)
                                    }>
                                    <LanguageIcon
                                        style={{ color: TEXT_COLOR }}
                                    />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={menuOpen}
                                onClose={handleClose}>
                                <MenuItem
                                    onClick={() => {
                                        setLang("en");
                                    }}>
                                    <Typography fontSize={12}>EN</Typography>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setLang("ar");
                                    }}>
                                    <Typography fontSize={12}>AR</Typography>
                                </MenuItem>
                            </Menu>
                            {loggedIn && (
                                <IconButton
                                    onClick={() => {
                                        setDrawerOpen(true);
                                    }}>
                                    <MenuIcon style={{ color: TEXT_COLOR }} />
                                </IconButton>
                            )}
                        </Container>
                    </Toolbar>
                </AppBar>
            </Box>
            <Outlet />
            <Drawer
                open={drawerOpen}
                anchor="right"
                onClose={() => {
                    setDrawerOpen(false);
                }}>
                <Box style={{ width: "20vw" }}>
                    <List>
                        {isSuperUser ? (
                            <ListItemButton
                                onClick={() => {
                                    navigate("/profile");
                                }}>
                                <Avatar />
                                <Typography style={{ padding: "0.8rem" }}>
                                    {`${langTokens.welcome_back_user} ${username}!`}
                                </Typography>
                            </ListItemButton>
                        ) : (
                            <ListItem>
                                <Avatar />
                                <Typography style={{ padding: "0.8rem" }}>
                                    {`${langTokens.welcome_back_user} ${username}!`}
                                </Typography>
                            </ListItem>
                        )}
                        <Divider />

                        {isSuperUser && (
                            <ListItemButton
                                onClick={() => {
                                    navigate("/user/posts");
                                }}>
                                <ListItemText primary={langTokens.your_posts} />
                            </ListItemButton>
                        )}

                        <ListItemButton
                            onClick={() => {
                                navigate("/user/comments");
                            }}>
                            <ListItemText primary={langTokens.your_comments} />
                        </ListItemButton>
                        {/* <Divider />
                        {isSuperUser && (
                            <>
                                <ListItemButton
                                    onClick={() => {
                                        navigate("/mod/feed");
                                    }}>
                                    <ListItemText
                                        primary={langTokens.mod_board}
                                    />
                                </ListItemButton>
                                <ListItemButton
                                    onClick={() => {
                                        navigate("/mod/settings");
                                    }}>
                                    <ListItemText
                                        primary={langTokens.settings}
                                    />
                                </ListItemButton>
                            </>
                        )}
                        <Divider /> */}
                        <ListItemButton
                            onClick={() => {
                                setDrawerOpen(false);
                                handleLogout();
                            }}>
                            <ListItemText primary={langTokens.logout} />
                        </ListItemButton>
                    </List>
                </Box>
            </Drawer>
        </ThemeProvider>
    );
};

export default NavBar;
