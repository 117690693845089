import React, { useState, useContext } from "react";
import UserContext from "../../../contexts/UserContext";
import { CARD_WIDTH } from "../../../theme";
import "../../../pages/singleVerse/singleVerse.css";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Chip,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LangContext from "../../../contexts/LangContext";

const ModReviewForm = ({
    comment,
    approved,
    setApproved,
    setDisplayForm,
    submitReview,
}) => {
    const { userId, modTags } = useContext(UserContext);
    const { langTokens } = useContext(LangContext);

    const [fieldText, setFieldText] = useState("");
    const [tags, setTags] = useState([]);
    const [errors, setErrors] = useState({});

    let navigate = useNavigate();
    const validate_review = () => {
        let err = {};
        if (approved === false && fieldText === "") {
            err["rejected"] = langTokens.review_err;
        }

        if (
            comment.reviews.filter((r) => r.reviewed_by.id === userId).length >
            0
        ) {
            err["exists"] = langTokens.review_err2;
        }
        setErrors(err);
        return Object.entries(err).length === 0;
    };

    return (
        <div>
            <Card
                style={{
                    width: CARD_WIDTH,
                    margin: "5px",
                    marginBottom: "1rem",
                }}>
                <CardContent
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                    }}>
                    <TextField
                        variant="outlined"
                        multiline
                        rows={4}
                        fullWidth
                        align="left"
                        error={
                            approved === false &&
                            errors["rejected"] !== undefined
                        }
                        helperText={
                            approved === false ? errors["rejected"] : ""
                        }
                        InputProps={{
                            style: { fontSize: 14, minRows: 2 },
                        }}
                        placeholder={langTokens.review_placeholder}
                        onChange={(e) => setFieldText(e.target.value)}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "flex-start",
                            paddingTop: "0.5rem",
                        }}>
                        <FormControlLabel
                            style={{ marginRight: "auto" }}
                            value="start"
                            control={
                                <Switch
                                    color={approved ? "success" : "error"}
                                    checked={approved}
                                    onClick={() => {
                                        setApproved(!approved);
                                    }}
                                />
                            }
                            label={
                                <Typography fontSize={13}>{langTokens.approved}</Typography>
                            }
                            labelPlacement="start"
                        />
                        <FormControl
                            sx={{ s: 1, width: "30vw", marginLeft: "auto" }}
                            size="small">
                            <InputLabel>
                                <Typography fontSize={13}>{langTokens.tags}</Typography>
                            </InputLabel>
                            <Select
                                multiple
                                value={tags}
                                onChange={(e) => {
                                    setTags(e.target.value);
                                }}
                                renderValue={(selected) => (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            gap: 0.5,
                                        }}>
                                        {selected.map((opt) => (
                                            <Chip
                                                key={opt.id}
                                                label={
                                                    <Typography fontSize={12}>
                                                        {opt.label}
                                                    </Typography>
                                                }
                                            />
                                        ))}
                                    </Box>
                                )}>
                                {modTags.map((tag) => (
                                    <MenuItem key={tag.id} value={tag}>
                                        <Typography fontSize={12}>
                                            {tag.label}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </CardContent>

                <CardActions disableSpacing>
                    <Box style={{ marginLeft: "auto" }}>
                        <Button
                            style={{ color: "default" }}
                            onClick={() => {
                                setDisplayForm(false);
                            }}>
                            {langTokens.cancel}
                        </Button>
                        <Button
                            style={{ color: "default" }}
                            onClick={() => {
                                if (userId === 0) {
                                    navigate("/login");
                                } else if (validate_review()) {
                                    submitReview({
                                        text: fieldText,
                                        tags: tags.map((t) => t.id),
                                    });
                                    setFieldText("");
                                    setErrors({});
                                }
                            }}>
                            {langTokens.submit}
                        </Button>
                    </Box>
                </CardActions>
            </Card>
        </div>
    );
};

export default ModReviewForm;
