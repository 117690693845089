import React, { createContext, useState, useEffect, useContext } from "react";
import LoadingContext from "./LoadingContext";

const LangContext = createContext({
    lang: "",
    setLang: () => {},
    langTokens: {},
    setLangTokens: () => {},
});

export const LangProvider = ({ children }) => {
    const [lang, setLang] = useState("en");
    const [langTokens, setLangTokens] = useState({});
    const { setIsLoading } = useContext(LoadingContext);

    useEffect(() => {
        const fetchTokens = () => {
            setIsLoading(true);
            fetch(`/translations/${lang}.json`)
                .then((res) => res.json())
                .then((data) => {
                    setLangTokens(data);
                    setIsLoading(false);
                })
                .catch((err) => console.log(err));
        };

        fetchTokens();
    }, [lang, setIsLoading]);

    return (
        <LangContext.Provider value={{ lang, setLang, langTokens }}>
            {children}
        </LangContext.Provider>
    );
};

export default LangContext;
