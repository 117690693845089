import React, { createContext, useState } from "react";
import { BASE_URL } from "../theme";

const VersesContext = createContext({
    verses: [],
    setVerses: () => {},
    chapter: {},
    setChapter: () => {},
    fetchVerses: () => {},
    count: 0,
    next: null,
    loadMoreVerses: () => {},
});

export const VersesProvider = ({ children }) => {
    const [verses, setVerses] = useState([]);
    const [chapter, setChapter] = useState({});

    const [next, setNext] = useState(null);

    const loadMoreVerses = async () => {
        try {
            const response = await fetch(next);

            const data = await response.json();

            data.results.sort((a, b) => a.num - b.num);

            setVerses((lst) => [...lst, ...data.results]);

            data.next ? setNext(data.next) : setNext(null);
        } catch (error) {
            console.error("Error fetching more verses:", error);
        }
    };
    const fetchVerses = async (c) => {
        try {
            const response = await fetch(
                `${BASE_URL}/quran/${c.id}/verses/`
            );
            const data = await response.json();

            data.results.sort((a, b) => a.num - b.num);
            setVerses(data.results);
            data.next ? setNext(data.next) : setNext(null);

            return data.results;
        } catch (error) {
            console.error("Error fetching verses:", error);
        }
    };
    return (
        <VersesContext.Provider
            value={{
                verses,
                setVerses,
                chapter,
                setChapter,
                fetchVerses,
                next,
                loadMoreVerses,
            }}>
            {children}
        </VersesContext.Provider>
    );
};

export default VersesContext;
