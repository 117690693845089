import {
    Chip,
    Card,
    CardContent,
    CardActions,
    Typography,
    Button,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../../contexts/UserContext";
import { BASE_URL, CARD_WIDTH } from "../../../theme";
import ModReviewForm from "../ModReviewForm";
import { createRanges } from "../../posts/VersePost";
import Review from "../Review";
import VerseChip from "../../generic/VerseChip";
import { Link } from "react-router-dom";
import LangContext from "../../../contexts/LangContext";

const ModComment = ({ comment, comments, setComments, pending = true }) => {
    const [displayForm, setDisplayForm] = useState(false);

    const [approved, setApproved] = useState(null);
    const [ranges, setRanges] = useState([]);
    const [reviews, setReviews] = useState(comment.reviews);
    const [open, setOpen] = useState(false);

    const { userId } = useContext(UserContext);
    const { lang, langTokens } = useContext(LangContext);

    useEffect(() => {
        const approvedSetter = () => {
            let approves = comment.reviews.filter((r) => r.approved === true);
            let disapproves = comment.reviews.filter(
                (r) => r.approved === false
            );

            let verdict = true;
            if (disapproves.length > approves.length) {
                verdict = false;
            }
            setApproved(verdict);
        };

        if (!pending) {
            approvedSetter();
        }

        createRanges(comment, setRanges);
    }, [comment, pending]);

    const submitReview = (input) => {
        let body = {
            approved: approved,
            comment: comment.id,
            reviewed_by: userId,
        };

        if (input.text !== "") {
            body["review_notes"] = input.text;
        }

        if (input.tags.length > 0) {
            body["tags"] = input.tags;
        }


        fetch(`${BASE_URL}/reviews/create/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
            body: JSON.stringify(body),
        })
            .then(async (res) => {
                if (!res.ok) {
                    return Promise.reject(res);
                }
                setDisplayForm(false);
                setComments(comments.filter((c) => c.id !== comment.id));
            })
            .catch((err) => console.log(err));
    };

    return (
        <div
            className="mod-comment"
            key={"comment-div-" + comment.id}
            style={{
                width: CARD_WIDTH + 100,
                display: "flex",
                justifyContent: "space-evenly",
                flexDirection: "row",
                paddingTop: "0.8rem",
                paddingBottom: "0.8rem",
            }}>
            <div>
                <Card
                    key={"comment-card-" + comment.id}
                    style={{
                        width: CARD_WIDTH,
                        margin: "5px",
                    }}>
                    <Box
                        style={{
                            display: "flex",
                            padding: "1rem",
                            gap: "0.3rem",
                        }}>
                        {lang === "en" ? (
                            <>
                                <Typography fontSize={13} fontWeight={"bold"}>
                                    {comment.user.username}
                                </Typography>
                                <Typography fontSize={13}>
                                    {langTokens.commented_on}
                                </Typography>
                                <Link to={`/post/${comment.post}/`}>
                                    <Typography
                                        color={"primary"}
                                        fontSize={13}
                                        fontStyle={"italic"}>
                                        {langTokens.this_post}
                                    </Typography>
                                </Link>

                                <Typography
                                    fontSize={13}
                                    style={{ marginLeft: "auto" }}>
                                    {new Date(comment.date_created)
                                        .toDateString()
                                        .slice(4)}
                                </Typography>
                            </>
                        ) : (
                            <>
                                <Link to={`/post/${comment.post}/`}>
                                    <Typography
                                        color={"primary"}
                                        fontSize={13}
                                        fontStyle={"italic"}>
                                        {langTokens.this_post}
                                    </Typography>
                                </Link>
                                <Typography fontSize={13}>
                                    {langTokens.commented_on}
                                </Typography>

                                <Typography fontSize={13} fontWeight={"bold"}>
                                    {comment.user.username}
                                </Typography>

                                <Typography
                                    fontSize={13}
                                    style={{ marginLeft: "auto" }}>
                                    {new Date(comment.date_created)
                                        .toDateString()
                                        .slice(4)}
                                </Typography>
                            </>
                        )}
                    </Box>
                    <CardContent
                        align="left"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                        }}>
                        <Typography
                            sx={{ whiteSpace: "pre-wrap" }}
                            fontSize={13}>
                            {comment.text !== undefined && comment.text}
                        </Typography>
                        <div style={{ display: "inline-flex" }}>
                            {ranges.map((range, i) => (
                                <VerseChip key={"chip" + i} range={range} />
                            ))}
                        </div>
                    </CardContent>
                    <CardActions disableSpacing>
                        {pending && (
                            <>
                                <Button
                                    color="gray"
                                    variant="outlined"
                                    sx={{
                                        borderRadius: "0.2rem",
                                        padding: "0.2rem",
                                        marginRight: "0.2rem",
                                    }}
                                    onClick={() => {
                                        setApproved(true);
                                        setDisplayForm(true);
                                    }}>
                                    {langTokens.approve}
                                </Button>
                                <Button
                                    color="gray"
                                    variant="outlined"
                                    sx={{
                                        borderRadius: "0.2rem",
                                        padding: "0.2rem",
                                        marginRight: "0.2rem",
                                        marginLeft: "0.2rem",
                                    }}
                                    onClick={() => {
                                        setApproved(false);
                                        setDisplayForm(true);
                                    }}>
                                    {langTokens.reject}
                                </Button>
                            </>
                        )}
                        {!pending && (
                            <Chip
                                variant="small"
                                label={
                                    approved
                                        ? langTokens.approved
                                        : langTokens.rejected
                                }
                                color={approved ? "success" : "error"}
                            />
                        )}

                        {reviews !== undefined && (
                            <Button
                                color="gray"
                                variant="outlined"
                                sx={{
                                    marginLeft: "auto",
                                    borderRadius: "0.2rem",
                                    padding: "0.2rem",
                                }}
                                onClick={() => {
                                    if (reviews.length > 0) setOpen(true);
                                }}>
                                {`${reviews.length} ${
                                    reviews.length === 1
                                        ? langTokens.review
                                        : langTokens.reviews
                                }`}
                            </Button>
                        )}
                    </CardActions>
                </Card>
                {displayForm && (
                    <ModReviewForm
                        comment={comment}
                        approved={approved}
                        setApproved={setApproved}
                        setDisplayForm={setDisplayForm}
                        submitReview={submitReview}
                    />
                )}

                <Dialog
                    maxWidth={"xl"}
                    open={open}
                    onClose={() => setOpen(false)}
                    align="left">
                    <Box sx={{ height: "80vh", overflow: "auto" }}>
                        <DialogTitle>{langTokens.reviews}</DialogTitle>
                        <DialogContent>
                            {reviews.map((review, i) => (
                                <div key={"review-" + i}>
                                    {review !== undefined && (
                                        <Card
                                            sx={{
                                                margin: "0.8rem",
                                            }}>
                                            <Review
                                                review={review}
                                                currReviews={reviews}
                                                setCurrReviews={setReviews}
                                            />
                                        </Card>
                                    )}
                                </div>
                            ))}
                        </DialogContent>
                    </Box>
                </Dialog>
            </div>
        </div>
    );
};

export default ModComment;
