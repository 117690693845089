import React, { useContext, useState } from "react";
import "./login.css";
import { useNavigate, Link } from "react-router-dom";
import { Button, TextField, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../theme";
import AuthContext from "../../contexts/AuthContext";
import LangContext from "../../contexts/LangContext";

const Login = () => {
    const { loginUser } = useContext(AuthContext);
    const { langTokens } = useContext(LangContext);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [valid, setValid] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    let navigate = useNavigate();

    const handleLogin = async () => {
        const res = await loginUser(username, password);
        setSubmitted(true);

        if (res) {
            setValid(true);
            navigate("/");
        } else {
            setValid(false);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <div className="login-page">
                <Typography variant="h3" style={{ padding: "1rem" }}>
                    {langTokens.login}
                </Typography>
                <form>
                    <label>
                        <TextField
                            id="username"
                            label={langTokens.username}
                            variant="outlined"
                            size="small"
                            required
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </label>

                    <label>
                        <TextField
                            id="password"
                            label={langTokens.password}
                            type="password"
                            size="small"
                            variant="outlined"
                            required
                            onChange={(e) => setPassword(e.target.value)}
                            error={valid === false && submitted === true}
                            helperText={
                                valid === false && submitted === true
                                    ? langTokens.login_error
                                    : ""
                            }
                        />
                    </label>

                    <label>
                        <Button
                            className="Button"
                            id="login-button"
                            variant="contained"
                            onClick={handleLogin}>
                            <Typography color="white">
                                {langTokens.login}
                            </Typography>
                        </Button>
                    </label>
                </form>
                <Typography>{langTokens.pre_create_account}</Typography>
                <Link
                    to="/signup"
                    color="primary"
                    style={{ textDecorationColor: "primary" }}>
                    <Typography color="primary" fontStyle={"italic"}>
                        {langTokens.create_account}
                    </Typography>
                </Link>
            </div>
        </ThemeProvider>
    );
};

export default Login;
