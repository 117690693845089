import {
    Box,
    Chip,
    CircularProgress,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemButton,
    Modal,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import LangContext from "../../../contexts/LangContext";
import SearchIcon from "@mui/icons-material/Search";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../theme";

const SearchBar = () => {
    const [results, setResults] = useState([]);

    const [query, setQuery] = useState("");
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [chipVal, setChipVal] = useState("chapters");

    const { langTokens } = useContext(LangContext);

    let navigate = useNavigate();

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const fetchSearch = async () => {
            try {
                const response = await fetch(
                    `${BASE_URL}/quran/search/?query=${query}&filter=${chipVal}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
                const data = await response.json();
                setResults(data.results);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching search results:", error);
            }
        };

        if (query.length > 1) {
            setLoading(true);
            console.log(query);
            fetchSearch();
        }
    }, [query, chipVal]);

    return (
        <div className="search-bar">
            <Tooltip title={langTokens.search}>
                <IconButton sx={{ color: "#ffa14b" }} onClick={handleOpen}>
                    <SearchIcon />
                </IconButton>
            </Tooltip>

            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "60vw",
                        height: "80vh",
                        bgcolor: "background.paper",
                        borderRadius: "1rem",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}>
                    <TextField
                        fullWidth
                        onChange={(e) => setQuery(e.target.value)}
                        sx={{
                            padding: "1rem",
                            borderRadius: "0.8rem",
                            backgroundColor: "white",
                        }}
                        placeholder={langTokens.search_placeholder}
                        InputProps={{
                            fontSize: 12,
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    sx={{ paddingRight: "0.5rem" }}>
                                    <SearchIcon color="primary" />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <>
                                    {loading && (
                                        <CircularProgress
                                            color="primary"
                                            size={20}
                                        />
                                    )}
                                </>
                            ),
                        }}
                    />

                    <Box
                        sx={{
                            width: "95%",
                            display: "flex",
                            justifyContent: "flex-start",
                        }}>
                        <Chip
                            sx={{ margin: "0.3rem" }}
                            label={
                                <Typography
                                    color={
                                        chipVal === "chapters"
                                            ? "white"
                                            : "black"
                                    }
                                    fontSize={12}>
                                    {langTokens.search_chapters}
                                </Typography>
                            }
                            color={
                                chipVal === "chapters" ? "primary" : "default"
                            }
                            onClick={() => {
                                setChipVal("chapters");
                                setResults([]);
                            }}
                        />
                        <Chip
                            sx={{ margin: "0.3rem" }}
                            label={
                                <Typography
                                    color={
                                        chipVal === "verses" ? "white" : "black"
                                    }
                                    fontSize={12}>
                                    {langTokens.search_verses}
                                </Typography>
                            }
                            color={chipVal === "verses" ? "primary" : "default"}
                            onClick={() => {
                                setChipVal("verses");
                                setResults([]);
                            }}
                        />
                        <Chip
                            sx={{ margin: "0.3rem" }}
                            label={
                                <Typography
                                    color={
                                        chipVal === "posts" ? "white" : "black"
                                    }
                                    fontSize={12}>
                                    {langTokens.search_posts}
                                </Typography>
                            }
                            color={chipVal === "posts" ? "primary" : "default"}
                            onClick={() => {
                                setChipVal("posts");
                                setResults([]);
                            }}
                        />
                    </Box>
                    <Box sx={{ overflow: "auto", width: "100%" }}>
                        <List>
                            {chipVal === "verses" &&
                                results.map((v, i) => (
                                    <ListItem key={"item-" + i}>
                                        <ListItemButton
                                            sx={{ width: "100%" }}
                                            onClick={() => {
                                                navigate(
                                                    `/quran/${v.chapter.num}/verse/${v.num}/`
                                                );
                                                handleClose();
                                            }}>
                                            <Box
                                                sx={{
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "0.5rem",
                                                }}>
                                                <Typography
                                                    color={"primary"}
                                                    fontWeight={"bold"}>
                                                    {`${v.chapter.name_eng} ${v.num} [${v.chapter.num}:${v.num}]`}
                                                </Typography>
                                                <Typography
                                                    align="right"
                                                    sx={{
                                                        whiteSpace: "pre-wrap",
                                                        fontSize: 18,
                                                        fontFamily: [
                                                            "'Amiri'",
                                                            "serif",
                                                        ].join(","),
                                                    }}>
                                                    {v.text_ara}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        whiteSpace: "pre-wrap",
                                                    }}>
                                                    {v.text_eng}
                                                </Typography>
                                            </Box>
                                        </ListItemButton>
                                    </ListItem>
                                ))}

                            {chipVal === "chapters" &&
                                results.map((c, i) => (
                                    <ListItem key={"item-" + i}>
                                        <ListItemButton
                                            onClick={() => {
                                                navigate(`/quran/${c.num}/`);
                                                handleClose();
                                            }}>
                                            <Typography
                                                sx={{ whiteSpace: "pre-wrap" }}>
                                                {`Chapter ${c.num}: ${c.name_eng} (${c.name_ara})`}
                                            </Typography>
                                        </ListItemButton>
                                    </ListItem>
                                ))}

                            {chipVal === "posts" &&
                                results.map((p, i) => (
                                    <ListItem key={"item-" + i}>
                                        <ListItemButton
                                            onClick={() => {
                                                navigate(`/post/${p.id}/`);
                                                handleClose();
                                            }}>
                                            <Typography
                                                sx={{ whiteSpace: "pre-wrap" }}>
                                                {p.text}
                                            </Typography>
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                        </List>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default SearchBar;
