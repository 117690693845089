import React, { useContext, useEffect, useState } from "react";
import ChaptersContext from "../../../contexts/ChaptersContext";
import UserContext from "../../../contexts/UserContext";
import {
    Checkbox,
    FormControlLabel,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    ListSubheader,
} from "@mui/material";
import LangContext from "../../../contexts/LangContext";

const ModChapters = ({ edit, selected, setSelected }) => {
    const { chapters } = useContext(ChaptersContext);
    const { modChapters } = useContext(UserContext);
    const { langTokens } = useContext(LangContext);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (edit) {
            setSelected(modChapters);
        }
    }, []);

    return (
        <div>
            <div
                style={{
                    height: "40vh",
                    width: "30vw",
                    overflow: "auto",
                }}>
                <List dense={true}>
                    <ListSubheader>{langTokens.click_to_select}</ListSubheader>
                    {chapters.map((chapter, i) => (
                        <ListItem key={"chapter-" + i}>
                            <ListItemButton
                                onClick={() => {
                                    if (!selected.includes(chapter.id)) {
                                        setSelected((lst) => [
                                            ...lst,
                                            chapter.id,
                                        ]);
                                    } else {
                                        setSelected(
                                            selected.filter(
                                                (c) => c !== chapter.id
                                            )
                                        );
                                    }
                                }}
                                selected={selected.includes(chapter.id)}>
                                <ListItemText
                                    primary={`${chapter.num}. ${chapter.name_eng} (${chapter.name_ara})`}
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </div>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checked}
                        onClick={() => {
                            setChecked(!checked);

                            if (!checked) {
                                setSelected(chapters.map((c) => c.id));
                            } else {
                                setSelected([]);
                            }
                        }}
                    />
                }
                label={langTokens.select_all}
                labelPlacement="start"
            />
        </div>
    );
};

export default ModChapters;
