import React, { useContext } from "react";
import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    CardActionArea,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CARD_WIDTH } from "../../../theme";
import LangContext from "../../../contexts/LangContext";

const VerseCard = ({ verse, chapter }) => {
    const { langTokens } = useContext(LangContext);
    let navigate = useNavigate();

    return (
        <div id={"verse-card-" + verse.id}>
            <Card sx={{ width: CARD_WIDTH }}>
                <CardActionArea
                    onClick={() => {
                        navigate(
                            "/quran/" + chapter.num + "/verse/" + verse.num
                        );
                    }}>
                    <CardHeader
                        title={chapter.num + ":" + verse.num}
                        align="left"
                        style={{ color: "#ff9d3f" }}
                    />
                    <CardContent>
                        <Typography
                            align="right"
                            style={{
                                fontSize: 24,
                                fontFamily: ["'Amiri'", "serif"].join(","),
                                paddingBottom: "0.5rem",
                            }}>
                            {verse.text_ara}
                        </Typography>
                        <Typography align="left">{verse.text_eng}</Typography>
                        <Typography
                            className="verse-footer"
                            fontSize={13}
                            align="right"
                            color="primary">
                            {`${verse.posts.length} ${
                                verse.posts.length === 1
                                    ? langTokens.interpretation
                                    : langTokens.interpretations
                            }`}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </div>
    );
};

export default VerseCard;
