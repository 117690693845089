import React from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { IconButton, Menu, MenuItem, Typography } from "@mui/material";

/**
 *
 * @param {options} options is an array of objects that each have a <label> and an <onClick function>
 */
const OptionsDropdown = ({ icon = "VERT", options }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton onClick={handleClick}>
                {icon === "HORIZ" ? <MoreHorizIcon /> : <MoreVertIcon />}
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                slotProps={{
                    paper: {
                        style: {
                            borderRadius: "0.5rem",
                            width: "12vw",
                            minHeight: "3vw",
                            boxShadow: "1px",
                            border: "0.2rem",
                        },
                    },
                }}>
                {options.map((option, i) => (
                    <MenuItem
                        key={"menu-option-" + i}
                        onClick={() => {
                            option.onClick();
                            handleClose();
                        }}>
                        <Typography fontSize={13}>{option.label}</Typography>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default OptionsDropdown;
