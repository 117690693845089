import {
    Button,
    TextField,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    Stack,
    Autocomplete,
    DialogActions,
    Slider,
    Box,
    Chip,
} from "@mui/material";
import React, { useState, useContext } from "react";
import MuiInput from "@mui/material/Input";
import LangContext from "../../../contexts/LangContext.js";
import VersesContext from "../../../contexts/VersesContext.js";
import ChaptersContext from "../../../contexts/ChaptersContext.js";

const AddVersesGroup = ({
    verse,
    postRefs,
    setPostRefs,
    errors,
    setErrors,
}) => {
    const { chapter } = useContext(VersesContext);
    const { langTokens } = useContext(LangContext);
    const { chapters, chapterOptions } = useContext(ChaptersContext);

    const [open, setOpen] = useState(false);
    const [min, setMin] = useState(verse ? verse.num : 1);
    const [max, setMax] = useState(verse ? verse.num : 1);
    const [dialogChap, setDialogChap] = useState(
        chapter ? chapter : chapters[0]
    );
    const [value, setValue] = useState(
        chapterOptions.find((o) => o.chapter.id === dialogChap.id)
    );

    const fillRefs = () => {
        setErrors({ ...errors, verses: "" });

        setPostRefs((obj) => ({
            ...obj,
            [dialogChap.id]: {
                verses: [],
                range:
                    dialogChap.name_eng +
                    " " +
                    (max === min ? min : min + "–" + max),
            },
        }));

        let lst = dialogChap.verses;
        for (let i = min - 1; i < max; i++) {
            setPostRefs((obj) => ({
                ...obj,
                [dialogChap.id]: {
                    ...obj[dialogChap.id],
                    verses: [...obj[dialogChap.id].verses, lst[i]],
                },
            }));
        }

        setOpen(false);
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
            }}>
            <Button
                color="primary"
                style={{
                    maxWidth: "max-content",
                    height: 30,
                    fontSize: "0.8rem",
                    borderRadius: "4rem",
                    marginBottom: "0.2rem",
                }}
                onClick={() => setOpen(true)}>
                {`+ ${langTokens.add_verses}`}
            </Button>

            <Stack
                direction={"row"}
                spacing={2}
                useFlexGap
                flexWrap={"wrap"}
                flexDirection={"space-evenly"}>
                {Object.keys(postRefs).map((k, i) => (
                    <Chip
                        key={"verses-chip-" + i}
                        onDelete={() => {
                            setPostRefs(
                                Object.keys(postRefs)
                                    .filter((key) => key !== k)
                                    .reduce((newObj, key) => {
                                        newObj[key] = postRefs[key];
                                        return newObj;
                                    }, {})
                            );
                        }}
                        size="small"
                        variant="outlined"
                        color="primary"
                        label={
                            <Typography
                                fontWeight={"500"}
                                fontSize={12}
                                fontStyle={"italic"}>
                                {postRefs[k].range}
                            </Typography>
                        }
                    />
                ))}
            </Stack>

            {errors.verses !== undefined && errors.verses.length > 0 && (
                <Typography color="error" fontSize={11}>
                    {errors.verses}
                </Typography>
            )}

            <Dialog open={open} onClose={() => setOpen(false)} align="left">
                <DialogTitle>{langTokens.dialog_title}</DialogTitle>
                <DialogContent style={{ padding: "1rem" }}>
                    <Autocomplete
                        defaultValue={value}
                        value={value}
                        controlled={"true"}
                        onChange={(_, value) => {
                            setValue(value);
                            if (value !== null) {
                                setDialogChap(value.chapter);
                            }
                        }}
                        isOptionEqualToValue={(option, value) => {
                            return option.chapter.id === value.chapter.id;
                        }}
                        getOptionLabel={(option) => option.label}
                        options={chapterOptions}
                        sx={{ width: "30vw" }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={langTokens.select_chapter}
                            />
                        )}
                    />
                    <Box
                        style={{
                            width: "30vw",
                            display: "flex",
                            justifyContent: "space-around",
                        }}>
                        <MuiInput
                            value={min}
                            style={{ margin: "1rem" }}
                            size="small"
                            onChange={(e) => {
                                let newMin = parseInt(e.target.value);
                                setMin(
                                    newMin > 0 && newMin <= max ? newMin : min
                                );
                            }}
                            inputProps={{
                                step: 1,
                                min: 1,
                                max: dialogChap.verses
                                    ? dialogChap.verses.length
                                    : chapters[0].verses.length,
                                type: "number",
                                "aria-labelledby": "input-slider",
                            }}
                        />
                        <Slider
                            style={{
                                width: "30vw",
                            }}
                            value={[min, max]}
                            min={1}
                            max={
                                dialogChap.verses
                                    ? dialogChap.verses.length
                                    : chapters[0].verses.length
                            }
                            onChange={(e) => {
                                setMin(e.target.value[0]);
                                setMax(e.target.value[1]);
                            }}
                            valueLabelDisplay="auto"
                        />
                        <MuiInput
                            value={max}
                            style={{ margin: "1rem" }}
                            size="small"
                            onChange={(e) => {
                                let newMax = parseInt(e.target.value);
                                setMax(
                                    newMax <= dialogChap.verses.length &&
                                        newMax >= min
                                        ? newMax
                                        : max
                                );
                            }}
                            inputProps={{
                                step: 1,
                                min: 1,
                                max: dialogChap.verses
                                    ? dialogChap.verses.length
                                    : chapters[0].verses.length,
                                type: "number",
                                "aria-labelledby": "input-slider",
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={fillRefs}>{langTokens.save}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AddVersesGroup;
