import React, { useContext, useEffect, useState } from "react";
import { TextField, CardContent, Button, Box } from "@mui/material";

import CardActions from "@mui/material/CardActions";
import "../../../theme/index.js";
import AddVersesGroup from "../../generic/AddVersesGroup/index.js";
import LoadingContext from "../../../contexts/LoadingContext.js";
import PostContext from "../../../contexts/PostContext.js";
import { createRanges } from "../VersePost/index.js";
import ChaptersContext from "../../../contexts/ChaptersContext.js";
import { BASE_URL } from "../../../theme/index.js";
import LangContext from "../../../contexts/LangContext.js";

const EditPostForm = ({
    post,
    setDisplayForm,
    updatePosts = false,
    setPosts = null,
}) => {
    const { setIsLoading } = useContext(LoadingContext);
    const { chapters } = useContext(ChaptersContext);
    const { state, dispatch } = useContext(PostContext);
    const { langTokens } = useContext(LangContext);

    const [text, setText] = useState(post.text);
    const [postRefs, setPostRefs] = useState({});

    const [errors, setErrors] = useState({});

    useEffect(() => {
        let inputRefs = {};
        let ranges = createRanges(post, null);

        for (let range of ranges) {
            let c = chapters.find((c) => c.id === range[0]);
            inputRefs = {
                ...inputRefs,
                [range[0]]: {
                    verses: post.verses
                        .filter((v) => v.chapter === range[0])
                        .map((v) => v.id),
                    range: `${c.name_eng} ${range[1]}–${range[2]}`,
                },
            };
        }

        setPostRefs(inputRefs);
    }, [post, chapters]);

    const editPost = async () => {
        let postVerses = Object.keys(postRefs).map((k) => postRefs[k].verses);
        postVerses = postVerses.flat(1);
        if (postVerses.length === 0) {
            setErrors({
                ...errors,
                verses: langTokens.post_err,
            });
        }
        if (text.length === 0) {
            setErrors({ ...errors, text: langTokens.post_err2 });
        }
        if (text.length !== 0 && postVerses.length !== 0) {
            let postChapters = Object.keys(postRefs).map((k) => parseInt(k));
            try {
                setIsLoading(true);

                const res = await fetch(
                    `${BASE_URL}/posts/edit/${post.id}/`,
                    {
                        method: "PATCH",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem(
                                "access"
                            )}`,
                        },
                        body: JSON.stringify({
                            text: text,
                            verses: postVerses,
                            chapters: postChapters,
                        }),
                    }
                );

                if (!res.ok) {
                    return Promise.reject(res);
                }
                const data = await res.json();

                if (updatePosts) {
                    // Update post display after editing
                    if (setPosts) {
                        // Post setting function is provided
                        setPosts((posts) =>
                            posts.map((p) =>
                                p.id === post.id
                                    ? {
                                          ...p,
                                          text: data.text,
                                          chapters: data.chapters,
                                          verses: data.verses,
                                      }
                                    : p
                            )
                        );
                    } else {
                        // Update the posts context using the reducer
                        dispatch({
                            type: "SET_FIELD",
                            field: "posts",
                            value: state.posts.map((p) =>
                                p.id === post.id
                                    ? {
                                          ...p,
                                          text: data.text,
                                          chapters: data.chapters,
                                          verses: data.verses,
                                      }
                                    : p
                            ),
                        });
                    }
                }

                setDisplayForm(false);
            } catch (err) {
                console.error("Error submitting post edit: ", err);
            }
        }
    };

    return (
        <div className="edit-post-form">
            <CardContent>
                <TextField
                    variant="standard"
                    multiline
                    align="left"
                    fullWidth
                    defaultValue={post.text}
                    inputProps={{ style: { fontSize: 14 } }}
                    onChange={(e) => {
                        setText(e.target.value);
                        setErrors({ ...errors, text: "" });
                    }}
                    error={errors.text !== undefined && errors.text.length > 0}
                    helperText={errors.text}
                />
            </CardContent>
            <CardActions
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "1rem",
                    paddingTop: "0",
                }}>
                <AddVersesGroup
                    verse={state.currVerse}
                    postRefs={postRefs}
                    setPostRefs={setPostRefs}
                    errors={errors}
                    setErrors={setErrors}
                />
                <Box sx={{ marginLeft: "auto" }}>
                    <Button
                        onClick={() => {
                            setDisplayForm(false);
                        }}>
                        {langTokens.cancel}
                    </Button>
                    <Button onClick={editPost}>{langTokens.submit}</Button>
                </Box>
            </CardActions>
        </div>
    );
};

export default EditPostForm;
