import React, { useContext, useEffect, useState } from "react";
import {
    Button,
    FormControlLabel,
    FormGroup,
    Switch,
    TextField,
    Typography,
    Alert,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import { BASE_URL, theme } from "../../../theme";
import ModChapters from "../../../components/mod/ModChapters";
import LangContext from "../../../contexts/LangContext";
import { Link } from "react-router-dom";

const ModSignUp = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");

    const [checked, setChecked] = useState(false);
    const [selected, setSelected] = useState([]);
    const [open, setOpen] = useState(false);
    const [errors, setErrors] = useState({});

    const { langTokens } = useContext(LangContext);

    useEffect(() => {
        setTimeout(() => {
            setOpen(false);
        }, 4000);
    }, []);

    function validate_fields() {
        let currErrors = {};

        if (password === "") {
            currErrors["password"] = langTokens.pass_error;
        }

        if (password2 === "" || password !== password2) {
            currErrors["password2"] = langTokens.pass2_error;
        }

        if (Object.entries(currErrors).length === 0) {
            return true;
        } else {
            setErrors(currErrors);
            return false;
        }
    }

    const submitReq = () => {
        if (validate_fields()) {
            fetch(`${BASE_URL}/accounts/mod/signup/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    // Authorization: `Bearer ${localStorage.getItem("access")}`,
                },
                body: JSON.stringify({
                    username: username,
                    password: password,
                    password2: password2,
                    chapters: selected,
                    is_superuser: true,
                }),
            })
                .then((res) => {
                    if (!res.ok) {
                        return Promise.reject(res);
                    }
                    setOpen(true);
                    setErrors({});
                })
                .catch((err) => err.json())
                .then((res) => {
                    if (res !== undefined) {
                        if (
                            res.password !== undefined &&
                            Array.isArray(res.password)
                        ) {
                            let errs = res.password.slice();
                            errs["password"] = langTokens.pass_error_weak;
                            setErrors(errs);
                        } else {
                            setErrors(res);
                        }
                    }
                });
        }
    };
    return (
        <ThemeProvider theme={theme}>
            <div
                className="mod-signup-page"
                style={{
                    // paddingTop: "2rem",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10rem", // remove this line later
                }}>
                <Typography variant="h3" style={{ padding: "2rem" }}>
                    {langTokens.sign_up}
                    {/* {langTokens.add_mod} */}
                </Typography>
                <form>
                    <label>
                        <TextField
                            id="username"
                            label={langTokens.username}
                            variant="outlined"
                            size="small"
                            required
                            error={errors.username !== undefined}
                            helperText={errors.username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </label>

                    <label>
                        <TextField
                            id="password"
                            label={langTokens.password}
                            type="password"
                            size="small"
                            variant="outlined"
                            required
                            error={errors.password !== undefined}
                            helperText={errors.password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </label>

                    <label>
                        <TextField
                            id="password2"
                            label={langTokens.password2}
                            size="small"
                            type="password"
                            variant="outlined"
                            required
                            error={errors.password2 !== undefined}
                            helperText={errors.password2}
                            onChange={(e) => setPassword2(e.target.value)}
                        />
                    </label>

                    {/* <FormGroup>
                        <FormControlLabel
                            value="start"
                            control={
                                <Switch
                                    checked={checked}
                                    onClick={() => {
                                        setChecked(!checked);
                                    }}
                                />
                            }
                            label={langTokens.moderate_comments}
                            labelPlacement="start"
                        />
                    </FormGroup> */}
                    {/* {checked && (
                        <ModChapters
                            edit={false}
                            selected={selected}
                            setSelected={setSelected}
                        />
                    )} */}

                    <label>
                        <Button
                            className="Button"
                            id="submit-button"
                            size="small"
                            variant="contained"
                            onClick={submitReq}>
                            <Typography color="white">
                                {langTokens.submit}
                            </Typography>
                        </Button>
                    </label>
                </form>

                {/* start of extra code added */}
                <Link to="/login" style={{ textDecorationColor: "primary" }}>
                    <Typography color="primary" fontStyle={"italic"}>
                        {langTokens.login_here}
                    </Typography>
                </Link>
                {/* end of extra code added */}

                {open && (
                    <Alert
                        severity="success"
                        onClose={() => {
                            setOpen(false);
                        }}>
                        {langTokens.user_alert}
                    </Alert>
                )}
            </div>
        </ThemeProvider>
    );
};

export default ModSignUp;
