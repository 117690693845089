import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

import React from "react";

const ErrorPage = () => {
    return (
        <div>
            <Typography>ERROR 404! Page not found.</Typography>
            <Link to="/">Go to Home</Link>
        </div>
    );
};

export default ErrorPage;
