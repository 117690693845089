import { Box } from "@mui/material";
import React from "react";

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            style={{
                padding: "2rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                overflow: "auto",
            }}
            {...other}>
            {value === index && <Box>{children}</Box>}
        </div>
    );
};
export default TabPanel;
