import React, { useContext, useState } from "react";
import {
    Card,
    CardContent,
    Typography,
    Chip,
    Box,
    Button,
} from "@mui/material";
import { BASE_URL, CARD_WIDTH } from "../../../theme";
import UserContext from "../../../contexts/UserContext";
import OptionsDropdown from "../../generic/OptionsDropdown";
import ReviewThread from "../ReviewThread";
import CommentThreadForm from "../../comments/CommentThreadForm";
import { useNavigate } from "react-router-dom";
import LangContext from "../../../contexts/LangContext";

const Review = ({ review, currReviews, setCurrReviews }) => {
    const [displayForm, setDisplayForm] = useState(false);
    const [displayThread, setDisplayThread] = useState(false);
    const { userId } = useContext(UserContext);
    const { langTokens } = useContext(LangContext);

    let navigate = useNavigate();

    const delReview = () => {
        fetch(`${BASE_URL}/reviews/del/${review.id}/`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
        })
            .then(async (res) => {
                if (res.status !== 204) {
                    return Promise.reject(res);
                }
                setCurrReviews(currReviews.filter((r) => r.id !== review.id));
            })
            .catch((err) => console.log(err));
    };

    const submitReviewComm = (text) => {
        fetch(`${BASE_URL}/reviews/comments/create/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
            body: JSON.stringify({
                text: text,
                user: userId,
                review: review.id,
            }),
        }).then((res) => {
            if (res.status !== 201) {
                return Promise.reject(res);
            }
            setDisplayForm(false);
        });
    };

    return (
        <div
            style={{
                width: CARD_WIDTH,
                paddingTop: "0.8rem",
                paddingBottom: "0.8rem",
            }}>
            <Card sx={{ border: "none", boxShadow: "none" }}>
                <CardContent align="left">
                    <div style={{ display: "flex", paddingBottom: "0.8rem" }}>
                        <Typography
                            sx={{ whiteSpace: "pre-wrap" }}
                            fontSize={13}>
                            {review.review_notes}
                        </Typography>
                    </div>
                    <Box sx={{ display: "flex", gap: "0.5rem" }}>
                        <Chip
                            variant="small"
                            label={
                                review.approved
                                    ? langTokens.approved
                                    : langTokens.rejected
                            }
                            color={review.approved ? "success" : "error"}
                        />

                        {review.tags.map((tag) => (
                            <Chip
                                key={tag.id}
                                label={
                                    <Typography fontSize={12}>
                                        {tag.label}
                                    </Typography>
                                }
                            />
                        ))}
                    </Box>

                    <div style={{ display: "flex" }}>
                        <Typography fontSize={12} style={{ padding: "0.5rem" }}>
                            {`Reviewed by ${review.reviewed_by.username} • 
                                ${new Date(review.date_reviewed)
                                    .toDateString()
                                    .slice(4)}`}
                        </Typography>
                        <Box>
                            <Button
                                color="inherit"
                                onClick={() =>
                                    userId === 0
                                        ? navigate("/login")
                                        : setDisplayForm(true)
                                }>
                                <Typography fontSize={13} fontWeight={"bold"}>
                                    {langTokens.reply}
                                </Typography>
                            </Button>
                            {review.review_comments.length > 0 &&
                                !displayThread && (
                                    <Button
                                        color="inherit"
                                        onClick={() => setDisplayThread(true)}>
                                        <Typography
                                            fontSize={13}
                                            fontWeight={"bold"}>
                                            {langTokens.view_replies}
                                        </Typography>
                                    </Button>
                                )}

                            {review.review_comments.length > 0 &&
                                displayThread && (
                                    <Button
                                        color="inherit"
                                        onClick={() => setDisplayThread(false)}>
                                        <Typography
                                            fontSize={13}
                                            fontWeight={"bold"}>
                                            {langTokens.hide_replies}
                                        </Typography>
                                    </Button>
                                )}
                        </Box>

                        <div style={{ marginLeft: "auto" }}>
                            <OptionsDropdown
                                icon={"HORIZ"}
                                options={[
                                    {
                                        label: langTokens.delete,
                                        onClick: delReview,
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </CardContent>
            </Card>

            {displayForm && (
                <Box sx={{ margin: "1rem" }}>
                    <CommentThreadForm
                        mod={true}
                        displayFunc={setDisplayForm}
                        submitFunc={submitReviewComm}
                        postId={review.id}
                    />
                </Box>
            )}

            {displayThread && (
                <ReviewThread comments={review.review_comments} setup={true} />
            )}
        </div>
    );
};

export default Review;
