import React, { useContext, useState } from "react";
import { BASE_URL, CARD_WIDTH } from "../../../theme";
import {
    Card,
    TextField,
    CardContent,
    CardHeader,
    IconButton,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import UserContext from "../../../contexts/UserContext.js";
import "../../../theme";
import AddVersesGroup from "../../generic/AddVersesGroup";
import LoadingContext from "../../../contexts/LoadingContext.js";
import PostContext from "../../../contexts/PostContext.js";
import LangContext from "../../../contexts/LangContext.js";

const PostForm = () => {
    const { setIsLoading } = useContext(LoadingContext);
    const { username, userId } = useContext(UserContext);
    const { langTokens } = useContext(LangContext);
    const { state, dispatch } = useContext(PostContext);

    const [post, setPost] = useState("");
    const [postRefs, setPostRefs] = useState({});

    const [errors, setErrors] = useState({});

    const submitPost = async () => {
        let postVerses = Object.keys(postRefs).map((k) => postRefs[k].verses);
        postVerses = postVerses.flat(1);
        if (postVerses.length === 0) {
            setErrors({
                ...errors,
                verses: langTokens.post_err,
            });
        }
        if (post.length === 0) {
            setErrors({ ...errors, post: langTokens.post_err2 });
        }
        if (post.length !== 0 && postVerses.length !== 0) {
            let postChapters = Object.keys(postRefs).map((k) => parseInt(k));
            try {
                setIsLoading(true);

                const res = await fetch(`${BASE_URL}/posts/add/`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "access"
                        )}`,
                    },
                    body: JSON.stringify({
                        text: post,
                        verses: postVerses,
                        chapters: postChapters,
                        user: userId,
                    }),
                });

                if (res.status !== 201) {
                    return Promise.reject(res);
                }
                const data = await res.json();

                dispatch({
                    type: "SET_FIELD",
                    field: "posts",
                    value: [
                        {
                            ...data,
                            user: { id: userId, username: username },
                            comments: [],
                            likes: [],
                            dislikes: [],
                        },
                        ...state.posts,
                    ],
                });

                setPost("");
                setPostRefs({});
            } catch (err) {
                console.error("Error submitting post: ", err);
            }
        }
    };

    return (
        <div className="post-form">
            <Card sx={{ width: CARD_WIDTH }}>
                <CardHeader
                    align="left"
                    avatar={
                        <Avatar sx={{ bgcolor: "#D27D2D" }}>
                            {username !== undefined &&
                                username[0] !== undefined &&
                                username[0].toUpperCase()}
                        </Avatar>
                    }
                    title={username}
                />
                <CardContent>
                    <TextField
                        variant="standard"
                        multiline
                        align="left"
                        fullWidth
                        placeholder={langTokens.post_placeholder}
                        inputProps={{ style: { fontSize: 14 } }}
                        onChange={(e) => {
                            setPost(e.target.value);
                            setErrors({ ...errors, post: "" });
                        }}
                        error={
                            errors.post !== undefined && errors.post.length > 0
                        }
                        helperText={errors.post}
                    />
                </CardContent>
                <CardActions
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        padding: "1rem",
                        paddingTop: "0",
                    }}>
                    <AddVersesGroup
                        verse={state.currVerse}
                        postRefs={postRefs}
                        setPostRefs={setPostRefs}
                        errors={errors}
                        setErrors={setErrors}
                    />

                    <IconButton
                        sx={{ alignContent: "right" }}
                        onClick={submitPost}>
                        <SendIcon />
                    </IconButton>
                </CardActions>
            </Card>
        </div>
    );
};

export default PostForm;
