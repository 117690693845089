import React, { useContext, useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import {
    Card,
    CardContent,
    CardHeader,
    CardActions,
    IconButton,
    Typography,
} from "@mui/material";

import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import UserContext from "../../../contexts/UserContext";
import { BASE_URL, CARD_WIDTH } from "../../../theme";
import "./verseposts.css";
import OptionsDropdown from "../../generic/OptionsDropdown";
import PostContext from "../../../contexts/PostContext";
import VerseChip from "../../generic/VerseChip";
import CommentIcon from "@mui/icons-material/Comment";
import { Link, useNavigate } from "react-router-dom";
import EditPostForm from "../EditPostForm";
import LangContext from "../../../contexts/LangContext";

const VersePost = ({ post }) => {
    const [ranges, setRanges] = useState([]);
    const [commLen] = useState(
        post.comments.filter(
            (c) => c.reviews.filter((r) => r.approved === true).length > 1
        ).length
    );

    const [displayEdit, setDisplayEdit] = useState(false);
    const { userId } = useContext(UserContext);
    const { state, dispatch } = useContext(PostContext);
    const { langTokens } = useContext(LangContext);
    const { posts, liked, disliked, numLikes, numDislikes } = state;

    let navigate = useNavigate();

    useEffect(() => {
        createRanges(post, setRanges);
    }, [post]);

    const likePost = async () => {
        await fetch(`${BASE_URL}/posts/like/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
            body: JSON.stringify({
                post: post.id,
                user: userId,
            }),
        })
            .then(async (res) => {
                if (res.status !== 201) {
                    return Promise.reject(res);
                } else {
                    dispatch({
                        type: "SET_FIELD",
                        field: "liked",
                        value: [...liked, post.id],
                    });

                    dispatch({
                        type: "SET_FIELD",
                        field: "numLikes",
                        value: {
                            ...numLikes,
                            [post.id]: numLikes[post.id] + 1,
                        },
                    });
                }
            })
            .catch((err) => console.log(err));
    };

    const dislikePost = () => {
        fetch(`${BASE_URL}/posts/dislike/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
            body: JSON.stringify({
                post: post.id,
                user: userId,
            }),
        })
            .then(async (res) => {
                if (res.status !== 201) {
                    return Promise.reject(res);
                } else {
                    dispatch({
                        type: "SET_FIELD",
                        field: "disliked",
                        value: [...disliked, post.id],
                    });

                    dispatch({
                        type: "SET_FIELD",
                        field: "numDislikes",
                        value: {
                            ...numDislikes,
                            [post.id]: numDislikes[post.id] + 1,
                        },
                    });
                }
            })
            .catch((err) => console.log(err));
    };

    const removeLike = async () => {
        await fetch(`${BASE_URL}/posts/del/like/`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
            body: JSON.stringify({
                post: post.id,
                user: userId,
            }),
        })
            .then(async (res) => {
                if (res.status !== 204) {
                    return Promise.reject(res);
                } else {
                    dispatch({
                        type: "SET_FIELD",
                        field: "liked",
                        value: liked.filter((c) => c !== post.id),
                    });

                    dispatch({
                        type: "SET_FIELD",
                        field: "numLikes",
                        value: {
                            ...numLikes,
                            [post.id]: numLikes[post.id] - 1,
                        },
                    });
                }
            })
            .catch((err) => console.log(err));
    };

    const removeDislike = () => {
        fetch(`${BASE_URL}/posts/del/dislike/`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
            body: JSON.stringify({
                post: post.id,
                user: userId,
            }),
        })
            .then(async (res) => {
                if (res.status !== 204) {
                    return Promise.reject(res);
                } else {
                    dispatch({
                        type: "SET_FIELD",
                        field: "disliked",
                        value: disliked.filter((c) => c !== post.id),
                    });

                    dispatch({
                        type: "SET_FIELD",
                        field: "numDislikes",
                        value: {
                            ...numDislikes,
                            [post.id]: numDislikes[post.id] - 1,
                        },
                    });
                }
            })
            .catch((err) => console.log(err));
    };

    const delPost = () => {
        fetch(`${BASE_URL}/posts/del/${post.id}/`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
        })
            .then(async (res) => {
                if (res.status !== 204) {
                    return Promise.reject(res);
                }
                dispatch({
                    type: "SET_FIELD",
                    field: "posts",
                    value: posts.filter((p) => p.id !== post.id),
                });
            })
            .catch((err) => console.log(err));
    };

    return (
        <div key={"verse-post-" + post.id}>
            <Card
                sx={{
                    width: CARD_WIDTH,
                    margin: "1rem",
                }}>
                <CardHeader
                    align="left"
                    avatar={
                        <Avatar sx={{ color: "deepOrange" }}>
                            {post.user.username[0].toUpperCase()}
                        </Avatar>
                    }
                    title={
                        <>
                            <div style={{ display: "inline-flex" }}>
                                <Link
                                    color="primary"
                                    to={`/scholars#${post.user.username}`}>
                                    <Typography
                                        fontSize={14}
                                        color="primary"
                                        sx={{ paddingRight: "0.2rem" }}>
                                        {`${post.user.username} `}
                                    </Typography>
                                </Link>
                                <Typography fontSize={14}>
                                    {` • ${new Date(post.date_created)
                                        .toDateString()
                                        .slice(4)}`}
                                </Typography>
                            </div>
                            {post.user.bio && post.user.subtitle !== "" && (
                                <Typography
                                    fontSize={12}
                                    fontStyle={"italic"}
                                    color="gray.main">
                                    {post.user.bio.subtitle}
                                </Typography>
                            )}
                        </>
                    }
                    action={
                        post.user["id"] === userId && (
                            <OptionsDropdown
                                options={[
                                    {
                                        label: langTokens.edit,
                                        onClick: () => {
                                            setDisplayEdit(true);
                                        },
                                    },
                                    {
                                        label: langTokens.delete,
                                        onClick: delPost,
                                    },
                                ]}
                            />
                        )
                    }
                />
                {displayEdit ? (
                    <EditPostForm
                        post={post}
                        setDisplayForm={setDisplayEdit}
                        updatePosts={true}
                    />
                ) : (
                    <>
                        <CardContent
                            align="left"
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                            }}>
                            <Typography
                                fontSize={14}
                                sx={{ whiteSpace: "pre-wrap" }}>
                                {post.text !== undefined && post.text}
                            </Typography>

                            <div
                                style={{
                                    display: "inline-flex",
                                    paddingBottom: "0",
                                    paddingTop: "0.3rem",
                                }}>
                                {ranges.map((range, i) => (
                                    <VerseChip key={"chip" + i} range={range} />
                                ))}
                            </div>
                        </CardContent>
                        <CardActions disableSpacing>
                            <Typography>
                                {!liked.includes(post.id) && (
                                    <IconButton
                                        onClick={() =>
                                            userId === 0
                                                ? navigate("/login")
                                                : likePost()
                                        }>
                                        <ThumbUpAltIcon />
                                    </IconButton>
                                )}
                                {liked.includes(post.id) && (
                                    <IconButton
                                        onClick={() =>
                                            userId === 0
                                                ? navigate("/login")
                                                : removeLike()
                                        }>
                                        <ThumbUpAltIcon color="primary" />
                                    </IconButton>
                                )}
                                {numLikes[post.id]}

                                {!disliked.includes(post.id) && (
                                    <IconButton
                                        onClick={() =>
                                            userId === 0
                                                ? navigate("/login")
                                                : dislikePost()
                                        }>
                                        <ThumbDownAltIcon />
                                    </IconButton>
                                )}
                                {disliked.includes(post.id) && (
                                    <IconButton
                                        onClick={() =>
                                            userId === 0
                                                ? navigate("/login")
                                                : removeDislike()
                                        }>
                                        <ThumbDownAltIcon color="primary" />
                                    </IconButton>
                                )}
                                {numDislikes[post.id]}

                                <IconButton
                                    onClick={() =>
                                        navigate(`/post/${post.id}/`)
                                    }>
                                    <CommentIcon fontSize="medium" />
                                </IconButton>
                                {commLen}
                            </Typography>
                        </CardActions>
                    </>
                )}
            </Card>
        </div>
    );
};

export default VersePost;

export const createRanges = (post, setRanges) => {
    let i = 1;
    let start = post.verses[0];
    let ranges = [];

    if (post.verses.length === 1) {
        ranges.push([start.chapter, start.num, start.num]);
    } else {
        while (i < post.verses.length) {
            if (post.verses[i].chapter !== start.chapter) {
                ranges.push([start.chapter, start.num, post.verses[i - 1].num]);
                start = post.verses[i];
            } else if (i === post.verses.length - 1) {
                ranges.push([start.chapter, start.num, post.verses[i].num]);
            }
            i++;
        }
    }

    if (setRanges) {
        setRanges(ranges);
    } else {
        return ranges;
    }
};
