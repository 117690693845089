import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import OptionsDropdown from "../../generic/OptionsDropdown";

import { CARD_WIDTH } from "../../../theme";
import { createRanges } from "../../posts/VersePost";
import VerseChip from "../../generic/VerseChip";
import LangContext from "../../../contexts/LangContext";

const UserComment = ({ comment, delComment }) => {
    const [ranges, setRanges] = useState([]);
    const { langTokens } = useContext(LangContext);

    useEffect(() => {
        createRanges(comment, setRanges);
    }, [comment]);

    return (
        <div style={{ paddingTop: "0.8rem", paddingBottom: "0.8rem" }}>
            <Card
                key={"comment-" + comment.id}
                style={{
                    width: CARD_WIDTH,
                }}>
                <CardHeader
                    align="left"
                    title={
                        <Typography fontSize={13}>
                            {`${comment.user.username} • 
                            ${new Date(comment.date_created)
                                .toDateString()
                                .slice(4)}
                            `}
                        </Typography>
                    }
                    action={
                        <OptionsDropdown
                            icon={"VERT"}
                            options={[
                                {
                                    label: langTokens.delete,
                                    onClick: () => {
                                        delComment(comment.id);
                                    },
                                },
                            ]}
                        />
                    }
                />

                <CardContent align="left">
                    <Typography sx={{ whiteSpace: "pre-wrap", fontSize: 13 }}>
                        {comment.text !== undefined && comment.text}
                    </Typography>

                    <div
                        style={{
                            display: "inline-flex",
                            paddingBottom: "0",
                            paddingTop: "0.3rem",
                        }}>
                        {ranges.map((range, i) => (
                            <VerseChip key={"chip" + i} range={range} />
                        ))}
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default UserComment;
