import { createTheme } from "@mui/material/styles";

export const CARD_WIDTH = "60vw";

export const BASE_URL = `https://compassionately.dgp.toronto.edu/api`;

export const theme = createTheme({
    palette: {
        primary: {
            light: "#ff9d3f",
            main: "#ea7600",
            dark: "#bb4d00",
            contrastText: "#1b1b1b",
        },
        gray: {
            light: "#d3d3d3",
            main: "#757575",
        },
    },
    typography: {
        fontFamily: ["'Inter'", "'Tajawal'", "sans-serif"].join(","),
        button: {
            textTransform: "none",
        },
    },
});
