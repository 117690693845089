import React, { useState, useEffect, useContext } from "react";
import {
    Button,
    Typography,
    Card,
    List,
    ListItem,
    ListItemButton,
    Divider,
    ListItemIcon,
    Collapse,
    ListItemText,
    Alert,
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import EditIcon from "@mui/icons-material/Edit";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import ModChapters from "../../../components/mod/ModChapters";
import UserContext from "../../../contexts/UserContext";
import { BASE_URL } from "../../../theme";
import LangContext from "../../../contexts/LangContext";

const ModSettings = () => {
    let navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState(false);
    const [selectChapters, setSelectChapters] = useState([]);

    const {langTokens} = useContext(LangContext);
    const { setModChapters } = useContext(UserContext);

    useEffect(() => {
        setTimeout(() => {
            setAlert(false);
        }, 4000);
    }, []);

    const editChapters = () => {
        fetch(`${BASE_URL}/accounts/mod/edit/`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
            body: JSON.stringify({
                chapters: selectChapters,
            }),
        })
            .then((res) => {
                if (!res.ok) {
                    return Promise.reject(res);
                }
                setAlert(true);
                setModChapters(selectChapters);
            })
            .catch((err) => console.log(err));
    };

    return (
        <div
            style={{
                overflow: "auto",
                position: "fixed",
                height: "90%",
                width: "100%",
            }}>
            <div
                style={{
                    padding: "5rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                <Card sx={{ width: "60vw" }}>
                    <Typography
                        variant="h3"
                        align="left"
                        style={{ padding: "2rem" }}>
                        {langTokens.settings}
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemButton
                                onClick={() => navigate("/mod/create")}>
                                <ListItemIcon>
                                    <PersonAddIcon />
                                </ListItemIcon>
                                <ListItemText primary={langTokens.add_mod} />
                            </ListItemButton>
                        </ListItem>
                        <Divider />
                        <ListItem
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}>
                            <ListItemButton
                                style={{
                                    width: "100%",
                                }}
                                onClick={() => setOpen(!open)}>
                                <ListItemIcon>
                                    <EditIcon />
                                </ListItemIcon>
                                <ListItemText primary={langTokens.change_mod_chap} />

                                {open ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                <Collapse
                                    in={open}
                                    timeout="auto"
                                    unmountOnExit
                                    disableSpacing>


                                    <ModChapters
                                        edit={true}
                                        selected={selectChapters}
                                        setSelected={setSelectChapters}
                                    />
                                    <Button
                                        onClick={editChapters}
                                        style={{ marginLeft: "0" }}>
                                        {langTokens.save}
                                    </Button>
                                    {alert && (
                                        <Alert
                                            severity="success"
                                            onClose={() => {
                                                setAlert(false);
                                            }}>
                                                {langTokens.change_alert}
                                        </Alert>
                                    )}
                                </Collapse>
                            </div>
                        </ListItem>
                 
                    </List>
                </Card>
            </div>
        </div>
    );
};

export default ModSettings;
